.custom-editor .tox-toolbar__primary{
    display: none !important; 
  }
  .custom-editor .mce-content-body{
    cursor: none !important; 
  }

  .custom-editor .tox-statusbar{
    display: none !important; 
  }
  .custom-editor .wrapper-class {
    padding: 1rem;
    border: none;
  }
  .custom-editor  .editor-class {
    background-color:lightgray;
    padding: 1rem;
    border: none ;
  }
  .custom-editor  .toolbar-class {
    border: none;
  }

 .custom-editor .tox-tinymce.tox-tinymce{border: transparent !important;}